/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on  all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page spesddcific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Add the necessary spacing to the body
  $(document).ready(function() {
    var mainheader = $('header .navbar');
    var body = $('body');
    var mainNavigation = $('header .navbar');
    var navBarBackground = $('.navbar-default').css('background-color');
    var inlineStyles;

    function hasOpacity(str) {
      if (str) {
        var tmp = str.split(',')[3];
        if (tmp) {
          var val = parseFloat(tmp);
          return (!isNaN(val) && val < 1);
        }
      }
      return false;
    }

    function carouselIsFirst() {
      var hasCarouselFirst = $('main.main .content-block').first().hasClass('carousel-outer-wrapper');
      return hasCarouselFirst;
    }

    // Start our JS inline Styles

    inlineStyles = '<style>';
    inlineStyles +=   '/* NOTE: You can change this CSS in theme/assets/js/_main.js */';


    /**
     *
     * Lets tackle our header offset, html offset etc
     *
     * This is based on, if the user is logged in, if the header is fixed
     * and also has a clause if the header is transparent
     *
     */
    if ($(mainheader).hasClass('navbar-fixed-top')) {

      var htmlMargin = $(mainNavigation).outerHeight();
      var mobileHtmlMargin = $(mainNavigation).outerHeight();

      // Add more margin for the admin bar
      if ($(body).hasClass('admin-bar')) {
        htmlMargin += 32;
        mobileHtmlMargin += 46;
      }

      // If the navbar is transparent we want the content to display below it
      if (hasOpacity(navBarBackground) && carouselIsFirst()) {
        htmlMargin -= $(mainNavigation).outerHeight();
      }

      inlineStyles =    '<style>';

      inlineStyles +=   'html { margin-top: '+ htmlMargin +'px !important; }';
      inlineStyles +=   '@media (max-width: 767px) { html { margin-top:'+ mobileHtmlMargin +'px !important; }}';
    }


    /**
     * We need to make sure that our events are of equal height when they're spat out
     *
     * Let's do this via min height based on the longest title we have
     *
     */
    var eventMaxHeight = -1;
    var eventTitleMaxHeight = -1;

    $('.events-outer-wrapper').each(function() {

      $('.event-wrapper .info-wrapper .title').each(function() {
        eventTitleMaxHeight = eventTitleMaxHeight > $(this).height() ? eventTitleMaxHeight : $(this).height();
      });

      $('.event-wrapper .info-wrapper .info-inner').each(function() {
        eventMaxHeight = eventMaxHeight > $(this).height() ? eventMaxHeight : $(this).height();
      });

    });

    inlineStyles += '@media (min-width: 767px) {';

      // inlineStyles += '.event-wrapper .info-wrapper .title { min-height: '+eventTitleMaxHeight+'px; }';

      inlineStyles += '.event-wrapper .info-wrapper .info-inner { min-height: '+eventMaxHeight+'px; }';

    inlineStyles += '}';


    // Lets close our styles and then add them

    inlineStyles +=   '</style>';
    $('head').append(inlineStyles);


  });


})(jQuery); // Fully reference jQuery after this point.
